import { directive as onClickaway } from "vue-clickaway";

export default {
  directives: {
    onClickaway: onClickaway,
  },
  props: {
    type: {
      type: String,
      default: "objectData",
    },
    value: {},
    label: {
      type: String,
      default: "",
    },
    progress: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    itemValue: {
      type: String,
      default: "default",
    },
    itemTitle: {
      type: String,
      default: "name",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      resetItem: false,
      page: 0,
      openSelect: false,
      selectedItem: this.value,
      selectedFilters: {
        category: "",
        type: {
          name: "toDefault",
          key: "default",
        },
      },
    };
  },
  created() {
    this.selectedItem = this.value;
  },
  methods: {
    changeSelect(item) {
      if (this.$route.query.hasOwnProperty("id")) this.$router.replace({ name: "catalog", query: "" });

      this.resetItem = false;
      this.openSelect = false;
      this.selectedItem = item;
      this.$emit("input", item);
    },
    away: function () {
      this.openSelect = false;
    },
  },
};
